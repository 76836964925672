import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  closeAlertDialog,
  openAlertDialog,
  setQuoteSharingDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { AppDispatch } from 'contexts/redux/store';
import { updateQuote } from 'contexts/redux/quote/quoteSlice';
import { IQuote, QuoteStatus } from 'services/@types';
import UploadService from 'services/upload.api';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';

interface IQuoteThreeDotsMenuProps {
  id: string;
  status: QuoteStatus;
  media: IQuote['media'];
  businessId: string;
}

export const QuoteThreeDotsMenu = memo((props: IQuoteThreeDotsMenuProps) => {
  const { id, status, media, businessId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const { appNavigate: navigate } = useAppNavigate();
  const myBusiness = useMyBusiness();

  const handleDownloadPdf = useCallback(
    (e: any) => {
      e.stopPropagation();
      dispatch(showSplashDialog({ title: 'dialogs.splash.please_wait' }));
      setTimeout(() => {
        navigate(`/main/finance/quotes/preview/${id}?download=true`);
      }, 400);
    },
    [navigate, id, dispatch],
  );

  const handleNotifySenderAndConfirmQuote = useCallback(
    (notify?: boolean) => {
      dispatch(
        updateQuote({
          id: id,
          updates: { status: 'approved' },
          query: {
            notify,
          },
        }),
      ).then(() => {
        toast({
          title: t('quote_list.quote_confirmed'),
          status: 'success',
          variant: 'main',
          position: 'top-right',
        });
      });
    },
    [dispatch, id, t, toast],
  );

  const handleConfirmQuote = useCallback(
    (e: any) => {
      e.stopPropagation();
      // if the quote is from the same business, we don't need to notify the sender
      if (businessId && businessId === myBusiness?.id) {
        handleNotifySenderAndConfirmQuote();
        return;
      }

      dispatch(
        openAlertDialog({
          title: t('quote_list.notify_sender_that_quote_is_approved'),
          content: t(
            'quote_list.notify_sender_that_quote_is_approved_description',
          ),
          onConfirm: () => {
            handleNotifySenderAndConfirmQuote(true);
            dispatch(closeAlertDialog());
          },
          onClose: (index: number) => {
            dispatch(closeAlertDialog({ index }));
            handleNotifySenderAndConfirmQuote();
          },
        }),
      );
    },
    [
      handleNotifySenderAndConfirmQuote,
      dispatch,
      t,
      myBusiness?.id,
      businessId,
    ],
  );

  const handleDownloadMedia = useCallback(
    async (e: any) => {
      e.stopPropagation();
      try {
        console.log('media: ', media);
        const response = await UploadService.getUpload(
          media[0].url,
          'original',
        );
        console.log('response', response);
        const imageData = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = imageData;
        a.download = media[0].displayName;
        a.click();
        toast({
          title: t('quote_list.download_quote_attachment_success'),
          status: 'success',
          variant: 'main',
          position: 'top-right',
        });
      } catch (error) {
        console.error('handleDownloadMedia error: ', error);
      }
    },
    [media, toast, t],
  );
  return (
    <Menu>
      <MenuButton onClick={(e) => e.stopPropagation()} w="max-content">
        <Icon as={FiMoreHorizontal} color="#93C3B3" w="24px" h="24px" />
      </MenuButton>
      <MenuList>
        <MenuItem
          h="44px"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/main/finance/quotes/preview/${id}`);
          }}>
          <Text variant="eventTabButtonTitle">{t('quote_list.view')}</Text>
        </MenuItem>
        <MenuItem
          h="44px"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              setQuoteSharingDialog({
                item: {
                  quoteId: id,
                },
              }),
            );
          }}>
          <Text variant="eventTabButtonTitle">{t('quote_list.share')}</Text>
        </MenuItem>
        <MenuItem h="44px" onClick={handleDownloadPdf}>
          <Text variant="eventTabButtonTitle">{t('quote_list.download')}</Text>
        </MenuItem>
        {media?.length > 0 && (
          <MenuItem h="44px" onClick={handleDownloadMedia}>
            <Text variant="eventTabButtonTitle">
              {t('quote_list.download_quote_attachment')}
            </Text>
          </MenuItem>
        )}
        <MenuItem
          h="44px"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/main/finance/quotes/${id}`);
          }}>
          <Text variant="eventTabButtonTitle">{t('quote_list.edit')}</Text>
        </MenuItem>
        {status !== 'approved' && (
          <MenuItem h="44px" onClick={handleConfirmQuote}>
            <Text variant="eventTabButtonTitle">{t('quote_list.confirm')}</Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
});
