import {
  Divider,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import AnchorMeetingForm from './components/AnchorMeetingForm';
import UpcomingMeetings from './components/UpcomingMeetings';
import useAnchorMeetingForm from './hooks/useAnchorMeetingForm';

export default function ScheduleAnchorMeetingDialog() {
  const {
    dialog,
    handleClose,
    event,
    meetings,
    handleCreateAnchorMeeting,
    anchorMeeting,
    handleChangeAnchorMeeting,
    selectedAnchorMeetingType,
    handleChangeAnchorMeetingType,
    errorList,
    handleChangeRemind,
    selectedRemind,
    displayingGuest,
    currentGuestInput,
    handleSelectGuest,
    handleRemoveGuest,
    handleChangeCurrentGuestInput,
    loading,
  } = useAnchorMeetingForm();

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: '0px', md: '30px' }}
        overflow="hidden"
        h={{ base: '100%', md: 'auto' }}>
        <Flex
          p="25px 24px"
          gap="24px"
          flexDirection={{ base: 'column', md: 'row' }}
          overflow="auto">
          <AnchorMeetingForm
            event={event}
            anchorMeeting={anchorMeeting}
            selectedAnchorMeetingType={selectedAnchorMeetingType}
            handleChangeAnchorMeeting={handleChangeAnchorMeeting}
            handleChangeAnchorMeetingType={handleChangeAnchorMeetingType}
            errorList={errorList}
            handleChangeRemind={handleChangeRemind}
            selectedRemind={selectedRemind}
            displayingGuest={displayingGuest}
            currentGuestInput={currentGuestInput}
            handleSelectGuest={handleSelectGuest}
            handleRemoveGuest={handleRemoveGuest}
            handleChangeCurrentGuestInput={handleChangeCurrentGuestInput}
            handleCreateAnchorMeeting={handleCreateAnchorMeeting}
            handleClose={handleClose}
            loading={loading}
          />
          <Divider
            w={{ base: '100%', md: '0px' }}
            h={{ base: '0px', md: '830px' }}
            border="1px solid #E6E6E6"
            opacity={1}
          />
          <UpcomingMeetings meetings={meetings} />
        </Flex>
      </ModalContent>
    </Modal>
  );
}
