import { Button, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import TrashIcon from 'assets/img/quote/QuotePreview.png';
import EventCard from 'components/eventsPickerDialog/eventCard';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IEvent, IQuote } from 'services/@types';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import { eventTypes } from 'variables/eventTypes';
import { useAppNavigate } from 'hooks/useAppNavigate';
import LocationSearchInput from 'components/placesAutocomplete';

export default function CreateQuoteHeader({
  handleChange,
  newQuote,
  handleChangeEventType,
  selectedEventType,
  handleOpenEventDialog,
  event,
}: {
  handleChange: (key: string, value: any) => void;
  newQuote: IQuote;
  handleChangeEventType: (selected: string) => void;
  selectedEventType: string[];
  handleOpenEventDialog: () => void;
  event: IEvent | null;
}) {
  const { t } = useTranslation();
  const params = useParams();
  const { appNavigate: navigate } = useAppNavigate();
  return (
    <VStack alignItems="start" gap="36px">
      <HStack justifyContent="space-between" width="100%">
        <VStack alignItems="start">
          <Text variant="cardTitle">
            {params.id
              ? t('create_quote.update_quote')
              : t('create_quote.title')}
          </Text>
          <Text variant="cardSubTitle">
            {newQuote?.quoteNumber ? `#${newQuote?.quoteNumber}` : ''}
          </Text>
        </VStack>
        <HStack alignItems="center">
          {event && (
            <EventCard
              event={event}
              onClick={handleOpenEventDialog}
              isQuoteEvent
              maxW={{ base: '100%', md: '320px' }}
              flex="1"
            />
          )}
          {!event && (
            <Button variant="h1cta" onClick={handleOpenEventDialog}>
              {t('create_quote.assign_event')}
            </Button>
          )}
          {params?.id && (
            <Flex
              cursor="pointer"
              bg="#0000004D"
              borderRadius="10px"
              w="32px"
              h="32px"
              onClick={() =>
                navigate(`/main/finance/quotes/preview/${params?.id}`)
              }
              alignItems="center"
              justifyContent="center">
              <Image src={TrashIcon} alt="trash" />
            </Flex>
          )}
        </HStack>
      </HStack>
      <Flex wrap="wrap" gap="12px" width="100%">
        <DropdownField
          label={t('create_quote.type')}
          options={eventTypes.map((type) => `event_types.${type}`)}
          placeholder={t('create_quote.type')}
          onSelected={handleChangeEventType}
          showedOptions={t(selectedEventType[0])}
          selectedOptions={selectedEventType}
          closeOnSelect
          menuButton={{
            w: {
              base: '100%',
              md: '239px',
            },
            borderColor: '#0000001A',
            bg: '#FFFFFF',
          }}
          flex={{ base: '1', md: '0' }}
        />
        <LocationSearchInput
          label={t('create_event.placeholder.location')}
          placeholder={t('create_event.placeholder.location')}
          onChange={(e) => {
            console.log('location search input ->', e);
            handleChange('location', { label: e, value: e });
          }}
          onSelect={(e) => {
            console.log('location search input ->', e);
            handleChange('location', e);
          }}
          // inputProps={
          // errorList.includes('location')
          //   ? {
          //       _empty: {
          //         borderColor: 'error.100',
          //       },
          //     }
          //   : {}
          // }
        />
        {/* <InputField
          label={t(`create_quote.location`)}
          value={newQuote?.location}
          onChange={(e) => handleChange('location', e.target.value)}
          flex="1"
          minWidth={{ base: '100%', md: '260px' }}
          w={{ base: '100%', md: '260px' }}
          placeholder={t(`create_quote.location`)}
        /> */}
        <InputDateField
          label={t(`create_quote.event_date`)}
          justifyContent="end"
          containerStyles={{ flex: '1', w: { base: '100%', md: '260px' } }}
          buttonStyles={{
            w: { base: '100%', md: '260px' },
            maxW: { base: '100%', md: '260px' },
            borderColor: '#0000001A',
            justifyContent: 'space-between',
          }}
          value={newQuote?.eventDate}
          imgSrc={CalendarIcon}
          onChange={(e: Date) => handleChange('eventDate', e.toString())}
          imgStyles={{
            bg: 'transparent',
          }}
        />
        <InputField
          label={t(`create_quote.number_of_guests`)}
          value={newQuote?.numberOfGuests}
          onChange={(e) => handleChange('numberOfGuests', e.target.value)}
          flex="1"
          minWidth={{ base: '100%', md: '260px' }}
          w={{ base: '100%', md: '260px' }}
          placeholder={t(`create_quote.number_of_guests`)}
        />
        <InputField
          label={t(`create_quote.description`)}
          value={newQuote?.description}
          onChange={(e) => handleChange('description', e.target.value)}
          flex="1"
          minWidth={{ base: '100%', md: '239px' }}
          w={{ base: '100%', md: '239px' }}
          placeholder={t(`create_quote.description`)}
        />
      </Flex>
    </VStack>
  );
}
