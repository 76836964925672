import { useToast } from '@chakra-ui/react';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  createClient,
  updateClient,
  fetchClients,
} from 'contexts/redux/client/clientSlice';
import { createInvite } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IClient, IClientContact } from 'services/@types';

export default function useClientForm({
  onClose,
}: {
  onClose: (callback?: () => void) => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<any>();
  const { editClient } = useSelector((state: RootState) => state.clients);
  const [newClient, setNewClient] = useState<Partial<IClient>>({
    type: 'private',
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [isInviteToBrilliant, setIsInviteToBrilliant] =
    useState<boolean>(false);
  const myBusiness = useMyBusiness();
  const isEdit = useMemo(() => !!editClient, [editClient]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (errors.includes(name)) {
        setErrors(errors.filter((field) => field !== name));
      }
      setNewClient((prev) => ({ ...prev, [name]: value }));
    },
    [errors],
  );

  const handleClearClient = useCallback(() => {
    setNewClient({
      type: 'private',
    });
  }, []);

  const requiredFields: (keyof IClient)[] = useMemo(() => {
    if (newClient.type === 'private') {
      return ['firstName', 'lastName', 'phone', 'email'];
    } else if (newClient.type === 'business') {
      return ['phone', 'email', 'contacts'];
    }
    setErrors([]);
    return [];
  }, [newClient?.type]);

  const validateNewClient = useCallback(() => {
    const newErrors = requiredFields.filter((field) => {
      return field === 'contacts'
        ? !newClient?.contacts || newClient?.contacts?.length === 0
        : !newClient?.[field as keyof IClient];
    });
    setErrors(newErrors);

    return newErrors.length === 0;
  }, [newClient, requiredFields]);

  const handleEditClient = useCallback(async () => {
    const updates: any = { ...newClient };
    updates.contacts = updates.contacts?.map((contact: any) => {
      return contact.id
        ? {
            id: contact.id,
          }
        : contact;
    });
    const clientData = await dispatch(
      updateClient({ clientId: newClient?.id, updates }),
    );

    if (!clientData?.error) {
      toast({
        title: t('clients.client_updated'),
        variant: 'main',
        position: 'top-right',
      });
      onClose();
      setNewClient({
        type: 'private',
      });
      return;
    }

    if (clientData?.payload?.response?.data?.message) {
      toast({
        title: clientData?.payload?.response?.data?.message,
        variant: 'error',
        position: 'top-right',
      });
    }
  }, [dispatch, toast, t, onClose, newClient]);

  const handleCreateClient = useCallback(async () => {
    const newClientData = await dispatch(createClient({ client: newClient }));

    if (!newClientData?.error) {
      await dispatch(fetchClients({}));
      toast({
        title: t('clients.client_created'),
        variant: 'main',
        position: 'top-right',
      });
      if (isInviteToBrilliant) {
        dispatch(
          createInvite({
            email: newClientData.email,
            businessID: myBusiness?.id,
            type: 'client',
            fullName: `${newClientData.firstName || ''} ${
              newClientData.lastName || ''
            }`,
            phone: newClientData.phone,
          }),
        );
      }
      onClose(newClientData?.payload);
      setNewClient({
        type: 'private',
      });
      return;
    }
    if (newClientData?.payload?.response?.data?.message) {
      toast({
        title: newClientData?.payload?.response?.data?.message,
        variant: 'error',
        position: 'top-right',
      });
    }
  }, [
    dispatch,
    toast,
    t,
    onClose,
    newClient,
    isInviteToBrilliant,
    myBusiness?.id,
  ]);

  const handleSubmit = useCallback(async () => {
    if (!validateNewClient()) return;

    if (isEdit) {
      await handleEditClient();
      return;
    }

    await handleCreateClient();
  }, [validateNewClient, isEdit, handleCreateClient, handleEditClient]);

  const handleFoundClient = useCallback((client: IClient) => {
    setNewClient(client);
  }, []);

  const handleCreateContact = useCallback(
    (contact: IClientContact) => {
      setNewClient((prevstate) => ({
        ...prevstate,
        contacts: [...(prevstate?.contacts || []), contact],
      }));
      setErrors(errors.filter((error) => error !== 'contacts'));
    },
    [setNewClient, errors],
  );

  const handleRemoveContact = useCallback(
    (email: string, index?: number) => {
      const newContacts = [...(newClient?.contacts || [])];
      newContacts.splice(index, 1);
      setNewClient((prevstate) => ({
        ...prevstate,
        contacts: newContacts,
      }));
    },
    [newClient?.contacts],
  );

  useEffect(() => {
    if (editClient) {
      console.log('editClient', editClient);
      setNewClient(editClient);
    } else {
      setNewClient({
        type: 'private',
      });
    }
  }, [editClient]);

  return {
    newClient,
    handleChange,
    handleSubmit,
    errors,
    isInviteToBrilliant,
    setIsInviteToBrilliant,
    handleFoundClient,
    handleClearClient,
    isEdit,
    handleCreateContact,
    handleRemoveContact,
  };
}
