import {
  Flex,
  Hide,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { setContractPreview } from 'contexts/redux/contract/contractSlice';
import { setUploadSignedContractDialog } from 'contexts/redux/dialog/dialogsSlice';
import { format } from 'date-fns';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { IContract, IEvent, IQuote } from 'services/@types';
import StatusBar from './StatusBar';

export default function ContractListItem({
  contract,
  handleDeleteContract,
}: {
  contract: IContract;
  handleDeleteContract: (
    e: React.MouseEvent<HTMLDivElement>,
    id: string,
  ) => void;
}) {
  const dispatch = useDispatch();
  const { appNavigate: navigate } = useAppNavigate();
  const { t } = useTranslation();

  const customers = useMemo(() => {
    return contract?.clients
      ?.map((client) =>
        client.client.type === 'business'
          ? client.client.company
          : client.client.firstName + ' ' + client.client.lastName,
      )
      .join(', ');
  }, [contract?.clients]);

  const eventResult = useMemo(() => {
    return contract?.eventId as unknown as IEvent;
  }, [contract?.eventId]);

  const quote = useMemo(() => {
    return contract?.quoteId as IQuote;
  }, [contract?.quoteId]);

  const eventName = useMemo(() => eventResult?.name, [eventResult?.name]);

  const quoteNumber = useMemo(() => {
    return quote?.quoteNumber;
  }, [quote?.quoteNumber]);

  const handleViewContract = useCallback(() => {
    dispatch(setContractPreview(contract));
    navigate(`/main/finance/contracts/preview`);
  }, [contract, dispatch, navigate]);

  const handleNavigateToEdit = useCallback(() => {
    navigate(`/main/finance/contracts/${contract?.id}`);
  }, [contract?.id, navigate]);

  const handleUploadSignedContract = useCallback(
    (e: any) => {
      e.stopPropagation();
      dispatch(
        setUploadSignedContractDialog({
          item: {
            contractId: contract?.id,
            signedContracts: contract?.signedContracts,
            status: contract?.status,
          },
        }),
      );
    },
    [contract?.id, contract?.signedContracts, dispatch, contract?.status],
  );

  return (
    <Flex
      key={contract.id}
      justify="space-between"
      py={4}
      px={6}
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}
      fontSize="sm"
      cursor="pointer"
      onClick={handleViewContract}>
      <Flex
        flex="1"
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        gap={2}>
        <Flex flex={1} align="center">
          <Text variant="clientDetailInfo">{customers}</Text>
        </Flex>
        <Flex flex={1} align="center">
          <Text variant="clientDetailInfo">
            {contract?.contractDate &&
              format(contract.contractDate, 'dd.MM.yy')}
          </Text>
        </Flex>
        <Flex flex={1} align="center">
          <Text variant="clientDetailInfo">{eventName}</Text>
        </Flex>
        <Hide below="md">
          <Flex flex={1} align="center">
            <Text variant="clientDetailInfo">{quoteNumber}</Text>
          </Flex>
        </Hide>
        <Hide below="md">
          <Flex flex={1} align="center">
            <StatusBar status={contract?.status} />
          </Flex>
          {/* <Flex flex={1} align="center">
            <Text variant="clientDetailInfo">{`0/${contract?.recipients?.length}`}</Text>
          </Flex> */}
        </Hide>
        <Flex flex="0 0 60px" justify="center" align="center">
          <Menu>
            <MenuButton
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <Icon as={FiMoreHorizontal} color="#93C3B3" w="24px" h="24px" />
            </MenuButton>
            <MenuList>
              <Hide above="md">
                {quoteNumber && (
                  <MenuItem>
                    <Text variant="eventTabButtonTitle">
                      {t('contracts.quote_number_info', { quoteNumber })}
                    </Text>
                  </MenuItem>
                )}
                {contract?.isDraft && (
                  <MenuItem onClick={handleNavigateToEdit}>
                    <Text variant="eventTabButtonTitle">
                      {t('contracts.edit_draft')}
                    </Text>
                  </MenuItem>
                )}
              </Hide>
              <MenuItem
                h="44px"
                onClick={(e: any) => handleDeleteContract(e, contract.id)}>
                <Text variant="eventTabButtonTitle">
                  {t('contracts.delete')}
                </Text>
              </MenuItem>
              <MenuItem onClick={handleUploadSignedContract}>
                <Text variant="eventTabButtonTitle">
                  {t('contracts.upload_signed_contract')}
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
}
