import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
  Business,
  BusinessCategory,
  IClient,
  IClientContact,
} from 'services/@types';
import BusinessService from 'services/business.api';
import { ClientBusiness } from './ClientBusiness';
import { Contact } from './Contact';

interface ClientCardProps {
  client: IClient;
  isSelected?: boolean;
  onSelect?: (clientId: string, contactId?: string) => void;
  selectedContacts?: { client: IClient; contacts: IClientContact[] } | null;
  [key: string]: any;
}
export default function ClientCard(props: ClientCardProps) {
  const { client, isSelected, onSelect, selectedContacts } = props;

  const isBusiness = useMemo(() => client.type === 'business', [client.type]);

  const { data: business } = useQuery<
    (Omit<Business, 'category'> & { category: BusinessCategory[] }) | null
  >({
    enabled: !!client?.businessID,
    queryKey: [`business-${client?.businessID}`],
    queryFn: async () => {
      try {
        const result = await BusinessService.getBusiness<
          Omit<Business, 'category'> & { category: BusinessCategory[] }
        >(client?.businessID, {
          populateCategories: true,
        });
        console.log('result business', result);
        if (result) {
          return result;
        }
        return null;
      } catch (err) {
        return null;
      }
    },
  });

  return !isBusiness ? (
    <Contact
      color={'brand.200'}
      title={`${client.firstName} ${client.lastName}`}
      clientId={client.id}
      email={client.email}
      address={client.address}
      isSelected={isSelected}
      onSelect={() => {
        onSelect(client.id);
      }}
    />
  ) : (
    <ClientBusiness
      client={client}
      business={business}
      selectedContacts={selectedContacts}
      onSelect={onSelect}
    />
  );
}
