import { CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import ThreeDotsBtn from 'components/buttons/ThreeDotsBtn';
// Custom components
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import TagsField from 'components/fields/TagsField';
import TextField from 'components/fields/TextField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { closeAddSupplierDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createInvite } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import {
  createSupplier,
  setCurrentSupplier,
  updateSupplier,
} from 'contexts/redux/supplier/supplierSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Business, ContactPerson, Supplier } from 'services/@types';
import SupplierService from 'services/supplier.api';
import { isValidEmail } from 'utils/isValidEmail';
import { isValidPhone } from 'utils/isValidPhone';
import { useFindSupplier } from '../hooks/useFindBusiness';
import { AddContactPerson } from './AddContactPerson';

export interface AddSupplierModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  editableSupplier?: Partial<Supplier>;
  onFoundExistingSupplier?: (supplier: Business) => void;
}

export const AddSupplierModal: React.FC<AddSupplierModalProps> = ({
  isOpen,
  onClose,
  editableSupplier,
  onFoundExistingSupplier,
}) => {
  const dispatch = useDispatch<any>();
  const { addSupplierDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const myBusiness = useMyBusiness();
  const myUser = useMyUser();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const {
    isOpen: isContactMenuOpen,
    onOpen: onContactMenuOpen,
    onClose: onContactMenuClose,
  } = useDisclosure();

  const toast = useToast();
  const { t } = useTranslation();
  const [supplierProps, setSupplierProps] = useState<Partial<Supplier>>({});
  const [errorLabels, setErrorLabels] = useState<{
    name: string;
    email: string;
    category: string;
    contacts: { email?: string; phone?: string }[];
  }>({
    name: '',
    email: '',
    category: '',
    contacts: [],
  });
  const [inviteCheckbox, setInviteCheckbox] = useState(false);
  const { businessCategories } = useSelector(
    (state: RootState) => state.businessCategories,
  );
  const { loading } = useSelector((state: RootState) => state.suppliers);

  const handleSupplierAction = useCallback((business: Business) => {
    setSupplierProps({
      businessID: business.id,
      phone: business.phone,
      name: business.businessName,
      email: business.email,
      address: business.address,
      vatId: business.businessVATId,
      category: business.category,
    });
  }, []);
  const { findBusinessBy, loadingExistingSupplier } =
    useFindSupplier(handleSupplierAction);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({ name: '', email: '', category: '', contacts: [] });
      setSupplierProps({ ...supplierProps, [e.target.id]: e.target.value });
    },
    [supplierProps],
  );
  const handleChangeBankDetails = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({
        name: '',
        email: '',
        category: '',
        contacts: [],
      });
      setSupplierProps({
        ...supplierProps,
        bankDetails: {
          ...supplierProps.bankDetails,
          [e.target.id]: e.target.value,
        },
      });
    },
    [supplierProps],
  );

  const handleClose = useCallback(() => {
    dispatch(closeAddSupplierDialog());
    onClose?.();
    setErrorLabels({ name: '', email: '', category: '', contacts: [] });
  }, [dispatch, onClose]);

  const handleTagsChange = useCallback((tags: string[]) => {
    setSupplierProps((prev) => ({
      ...prev,
      tags: tags.filter((tag) => tag.length > 0),
    }));
  }, []);

  const handleContactChange = useCallback(
    (contactDetails: ContactPerson, index: number) => {
      setErrorLabels({
        name: '',
        email: '',
        category: '',
        contacts: [],
      });
      if (Object.keys(contactDetails).length === 0) {
        console.log('empty contact...');
        return;
      }
      setSupplierProps((prevProps) => {
        const newContacts = prevProps.contacts ? [...prevProps.contacts] : [];
        newContacts[index] = contactDetails;
        return { ...prevProps, contacts: newContacts };
      });
    },
    [],
  );

  useEffect(() => {
    if (editableSupplier) {
      setSupplierProps({
        ...editableSupplier,
        category: editableSupplier.category?.map((c: any) =>
          typeof c === 'string' ? c : c?.id,
        ),
      });
    } else {
      setSupplierProps({});
    }
  }, [editableSupplier, isOpen, handleClose]);

  const validate = useCallback(() => {
    let isValid = true;
    if (!supplierProps.name) {
      setErrorLabels((prev) => ({
        ...prev,
        name: t('suppliers.errors.name'),
      }));
      isValid = false;
    }
    if (!supplierProps.email) {
      setErrorLabels((prev) => ({
        ...prev,
        email: t('suppliers.errors.email'),
      }));
      isValid = false;
    }
    if (!isValidEmail(supplierProps.email)) {
      setErrorLabels((prev) => ({
        ...prev,
        email: t('suppliers.errors.invalid_email'),
      }));
      isValid = false;
    }
    if (!supplierProps.category || supplierProps.category.length === 0) {
      setErrorLabels((prev) => ({
        ...prev,
        category: t('suppliers.errors.category'),
      }));
      isValid = false;
    }

    if (supplierProps.contacts?.length > 0) {
      supplierProps.contacts.forEach((contact) => {
        if (
          !contact.email ||
          !isValidEmail(contact.email) ||
          !contact.phone ||
          !isValidPhone(contact.phone)
        ) {
          isValid = false;
        }
        setErrorLabels((prev) => {
          const newContacts = [...prev.contacts];
          const newContactErrors: { email?: string; phone?: string } = {};
          if (!contact.email || !isValidEmail(contact.email)) {
            newContactErrors.email = t('suppliers.errors.invalid_email');
          }
          if (!contact.phone || !isValidPhone(contact.phone)) {
            newContactErrors.phone = t('suppliers.errors.invalid_phone');
          }
          if (Object.keys(newContactErrors).length > 0) {
            newContacts.push(newContactErrors);
          } else {
            newContacts.push({});
          }
          return { ...prev, contacts: newContacts };
        });
      });
    }

    return isValid;
  }, [
    supplierProps?.name,
    supplierProps?.email,
    supplierProps?.category,
    t,
    supplierProps?.contacts,
  ]);

  const onAddContact = useCallback(() => {
    if (supplierProps?.contacts?.length === 2) {
      console.debug('max contacts reached');
      return;
    }
    if (!supplierProps.contacts || supplierProps.contacts?.length === 0) {
      setSupplierProps((prev) => ({ ...prev, contacts: [{}] }));
    }
    setSupplierProps((prev) => ({
      ...prev,
      contacts: [
        ...prev.contacts,
        {
          fullName: '',
          email: '',
          phone: '',
        },
      ],
    }));
    console.log('contacts ->', supplierProps.contacts);
  }, [supplierProps]);
  const onRemoveContact = useCallback((index: number) => {
    console.log('remove contact');
    setSupplierProps((prev) => ({
      ...prev,
      contacts: prev.contacts?.filter((_, i) => i !== index),
    }));
  }, []);

  const handleSave = useCallback(async () => {
    if (!validate()) {
      return;
    }
    if (editableSupplier) {
      const res = await dispatch(
        updateSupplier({
          supplierId: supplierProps.id,
          updates: supplierProps,
        }),
      );
      if (res?.error) {
        toast({
          variant: 'error',
          position: 'top-right',
          title: res?.payload?.response?.data?.message,
        });
        return;
      }
      if (res?.payload) {
        dispatch(setCurrentSupplier(res.payload));
      }
      toast({
        variant: 'main',
        title: t('suppliers.toast.supplier_updated'),
      });
    } else {
      // check if supplier already exists
      const supplierExists = await SupplierService.getSuppliers({
        email: supplierProps?.email,
        creatorUserID: myUser?.id,
        creatorBusinessID: myBusiness?.id,
      });

      if (supplierExists?.results?.length > 0) {
        toast({
          variant: 'error',
          position: 'top-right',
          title: t('suppliers.toast.supplier_already_exists'),
        });
        return;
      }
      const res = await dispatch(
        createSupplier({ invite: inviteCheckbox, supplier: supplierProps }),
      );
      if (res?.error) {
        toast({
          variant: 'error',
          position: 'top-right',
          title: res?.payload?.response?.data?.message,
        });
        return;
      }
      if (inviteCheckbox) {
        dispatch(
          createInvite({
            email: supplierProps.email,
            businessID: myBusiness.id,
            type: 'supplier',
            tags: supplierProps.tags,
            fullName: supplierProps.name,
            phone: supplierProps.phone,
          }),
        );
      }
      toast({
        variant: 'main',
        title: t('suppliers.toast.supplier_added'),
      });
    }
    handleClose();
  }, [
    dispatch,
    editableSupplier,
    inviteCheckbox,
    myBusiness?.id,
    handleClose,
    supplierProps,
    t,
    toast,
    validate,
    myUser?.id,
  ]);
  console.log('supplierProps ->', supplierProps?.contacts);
  return (
    <Modal isOpen={isOpen || !!addSupplierDialog} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={'3xl'}
        mx={2}
        maxW={'1102px'}
        px={'20px'}
        pb={'20px'}
        gap={4}
        pt={'20px'}>
        <ModalHeader p={0}>
          <HStack gap={2} align={{ sm: 'start', md: 'center' }}>
            <VStack align="start" flex={1} spacing={0}>
              <Text variant="cardTitle">
                {editableSupplier
                  ? t('suppliers.edit_supplier')
                  : t('suppliers.add_supplier')}
              </Text>
              <Text variant="cardSubTitle">
                {t('suppliers.modals.add_supplier.title')}
              </Text>
            </VStack>
            <Icon
              as={CloseIcon}
              alignSelf={'top'}
              color="#999EA9"
              right={0}
              cursor={'pointer'}
              w="12px"
              h="12px"
              position={'relative'}
              top={0}
              onClick={handleClose}
            />
          </HStack>
        </ModalHeader>
        <Flex mt={'24px'} direction="column" align="center" position="relative">
          <Flex p={0} w="100%">
            <Flex
              direction="row"
              w="100%"
              gap={6}
              wrap="wrap"
              justify={'space-between'}>
              <VStack
                gap={4}
                flex={1}
                align="start"
                minWidth={310}
                maxW={{ base: '100%', md: 310 }}>
                <InputField
                  id="name"
                  w="100%"
                  value={supplierProps?.name}
                  placeholder={t('suppliers.modals.add_supplier.business_name')}
                  onChange={handleChange}
                  label={t('suppliers.modals.add_supplier.business_name')}
                  errorLabel={errorLabels.name}
                  borderColor={
                    errorLabels.name
                      ? 'red.500'
                      : supplierProps?.name
                      ? '#465D55'
                      : 'secondaryGray.100'
                  }
                />
                <InputField
                  value={supplierProps?.email}
                  id="email"
                  isLoading={loadingExistingSupplier}
                  onBlur={(e: any) => {
                    if (
                      !editableSupplier &&
                      e.target.value &&
                      e.target.value.includes('@')
                    ) {
                      findBusinessBy(e.target.value, null);
                    }
                  }}
                  w="100%"
                  onChange={handleChange}
                  placeholder={t('suppliers.modals.add_supplier.email_address')}
                  label={t('suppliers.modals.add_supplier.email_address')}
                  errorLabel={errorLabels.email}
                  borderColor={
                    errorLabels.email
                      ? 'red.500'
                      : supplierProps?.email
                      ? '#465D55'
                      : 'secondaryGray.100'
                  }
                />
                <Checkbox
                  display={editableSupplier?.id ? 'none' : 'flex'}
                  checked={inviteCheckbox}
                  onChange={(e: any) => setInviteCheckbox(e.target.checked)}>
                  <Text fontSize="sm" color={'brand.900'}>
                    {t('suppliers.modals.add_supplier.invite_to_platform')}
                  </Text>
                </Checkbox>

                <InputField
                  id="phone"
                  value={supplierProps?.phone}
                  w="100%"
                  onBlur={(e: any) => {
                    if (!editableSupplier && e.target.value) {
                      findBusinessBy(null, e.target.value);
                    }
                  }}
                  onChange={handleChange}
                  placeholder="eg. 051-2332456"
                  label={t('suppliers.modals.add_supplier.business_phone')}
                  borderColor={supplierProps?.phone && '#465D55'}
                />

                <InputField
                  id="address"
                  w="100%"
                  value={supplierProps?.address}
                  onChange={handleChange}
                  placeholder={t(
                    'suppliers.modals.add_supplier.business_address',
                  )}
                  label={t('suppliers.modals.add_supplier.business_address')}
                  borderColor={supplierProps?.address && '#465D55'}
                />
                <InputField
                  id="vatId"
                  value={supplierProps?.vatId}
                  w="100%"
                  onChange={handleChange}
                  placeholder="eg. 123456789"
                  label={t('suppliers.modals.add_supplier.business_vat_id')}
                  borderColor={supplierProps?.vatId && '#465D55'}
                />
                <DropdownField
                  w="100%"
                  label={t('suppliers.modals.add_supplier.business_category')}
                  options={businessCategories?.map(
                    (category) => `categories.${category.name}`,
                  )}
                  placeholder={t('suppliers.supplier_category_placeholder')}
                  onSelected={(selected: any) => {
                    console.log('on selected ->', selected);
                    const selectedCategory = businessCategories.find(
                      (bc) => bc.name === selected.split('.').pop(),
                    );
                    const newCategories = [...(supplierProps?.category || [])];
                    if (!newCategories.includes(selectedCategory?.id)) {
                      newCategories.push(selectedCategory?.id as string);
                    } else {
                      newCategories.splice(
                        newCategories.indexOf(selectedCategory?.id as string),
                        1,
                      );
                    }

                    console.log('selected cat ->', newCategories);
                    setSupplierProps({
                      ...supplierProps,
                      category: newCategories,
                    });
                    setErrorLabels({
                      name: '',
                      email: '',
                      category: '',
                      contacts: [],
                    });
                  }}
                  name="category"
                  selectedOptions={supplierProps?.category?.map((sc) => {
                    const selectedCategory = businessCategories.find(
                      (bc) => bc.id === sc,
                    );
                    return `categories.${selectedCategory?.name}`;
                  })}
                  menuButton={{ w: { base: '100%', md: '310px' } }}
                  error={errorLabels.category}
                  showedOptions={supplierProps?.category
                    ?.map((sc) => {
                      const selectedCategory = businessCategories.find(
                        (bc) => bc.id === sc,
                      );
                      return t(`categories.${selectedCategory?.name}`);
                    })
                    .join(',')}
                />
              </VStack>

              <VStack
                gap={4}
                flex={1}
                minWidth={310}
                maxW={{ base: '100%', md: 310 }}>
                {/* business id, categories, tag services, internal notes */}

                <InputField
                  id="bankName"
                  value={supplierProps?.bankDetails?.bankName}
                  w="100%"
                  onChange={handleChangeBankDetails}
                  placeholder="eg. 123456789"
                  label={t('suppliers.modals.add_supplier.bank_name')}
                  borderColor={
                    supplierProps?.bankDetails?.bankName && '#465D55'
                  }
                />
                <InputField
                  id="branchCode"
                  value={supplierProps?.bankDetails?.branchCode}
                  w="100%"
                  onChange={handleChangeBankDetails}
                  placeholder="eg. 123456789"
                  label={t('suppliers.modals.add_supplier.bank_branch')}
                  borderColor={
                    supplierProps?.bankDetails?.branchCode && '#465D55'
                  }
                />
                <InputField
                  id="accountNumber"
                  value={supplierProps?.bankDetails?.accountNumber}
                  w="100%"
                  onChange={handleChangeBankDetails}
                  placeholder="eg. 123456789"
                  label={t('suppliers.modals.add_supplier.account_number')}
                  borderColor={
                    supplierProps?.bankDetails?.accountNumber && '#465D55'
                  }
                />

                <TagsField
                  placeholderTags={supplierProps?.tags}
                  placeholder="eg. Delivery, Pickup"
                  onTagsChange={handleTagsChange}
                  w="100%"
                  label={t('suppliers.modals.add_supplier.tag_services')}
                />
                <TextField
                  id="internalNotes"
                  w="100%"
                  value={supplierProps?.internalNotes}
                  minH={130}
                  onChange={handleChange}
                  tooltipTopLabel={t(
                    'suppliers.modals.add_supplier.internal_notes_tooltip',
                  )}
                  placeholder="eg. Notes about the supplier"
                  label={t('suppliers.modals.add_supplier.internal_notes')}
                />
              </VStack>
              <HStack
                align="start"
                justify={'start'}
                gap={4}
                w={{ base: '100%', md: 'fit-content' }}>
                <Divider
                  orientation={'vertical'}
                  display={{ base: 'none', md: 'block' }}
                />
                <VStack
                  align="start"
                  flex={1}
                  minWidth={310}
                  maxW={{ base: '100%', md: 310 }}>
                  <HStack w="100%">
                    <Text
                      fontSize={'xl'}
                      fontWeight="bold"
                      flex={1}
                      color="#000000CC">
                      {t('suppliers.modals.add_supplier.contact_person')}
                    </Text>
                    <Menu
                      isOpen={isContactMenuOpen}
                      onOpen={onContactMenuOpen}
                      onClose={onContactMenuClose}>
                      <ThreeDotsBtn
                        onClick={onContactMenuOpen}
                        mb="0px"
                        me="8px"
                        bg="transparent"
                        iconColor="#7BA395"
                      />
                      <MenuList
                        minW="unset"
                        maxW="180px !important"
                        border="transparent"
                        backdropFilter="blur(63px)"
                        borderRadius="20px"
                        py="10px"
                        px="14px">
                        <MenuItem
                          transition="0.2s linear"
                          color={textColor}
                          onClick={onAddContact}
                          py={2}
                          px={0}
                          borderRadius="8px"
                          display={
                            supplierProps.contacts?.length === 2
                              ? 'none'
                              : 'block'
                          }
                          _active={{
                            bg: 'transparent',
                          }}
                          _focus={{
                            bg: 'transparent',
                          }}>
                          <Text fontSize="sm" fontWeight="400">
                            {t('suppliers.modals.contact_person.menu.add')}
                          </Text>
                        </MenuItem>
                        {supplierProps.contacts?.length > 1 &&
                          supplierProps.contacts?.map((_, index) => (
                            <MenuItem
                              py={2}
                              key={index}
                              transition="0.2s linear"
                              px="0px"
                              borderRadius="8px"
                              color={textColor}
                              onClick={() => onRemoveContact(index)}
                              _active={{
                                bg: 'transparent',
                              }}
                              _focus={{
                                bg: 'transparent',
                              }}>
                              <Text
                                color="red.500"
                                fontSize="sm"
                                fontWeight="400">
                                {t(
                                  'suppliers.modals.contact_person.menu.remove_contact',
                                  {
                                    index: index + 1,
                                  },
                                )}
                              </Text>
                            </MenuItem>
                          ))}
                      </MenuList>{' '}
                    </Menu>
                  </HStack>
                  {supplierProps.contacts?.length > 0 &&
                    supplierProps.contacts?.map((contact, index) => (
                      <AddContactPerson
                        key={index}
                        label={t('suppliers.modals.contact_person.contact', {
                          index: index + 1,
                        })}
                        contact={contact}
                        handleContactChange={(contact) =>
                          handleContactChange(contact, index)
                        }
                        errorLabels={errorLabels.contacts?.[index]}
                      />
                    ))}
                  {!supplierProps?.contacts && (
                    <AddContactPerson
                      label={t('suppliers.modals.contact_person.contact', {
                        index: 1,
                      })}
                      contact={supplierProps.contacts?.[0] || {}}
                      handleContactChange={(contact) =>
                        handleContactChange(contact, 0)
                      }
                      errorLabels={errorLabels.contacts?.[0]}
                    />
                  )}
                  <HStack
                    display={
                      !supplierProps?.contacts ||
                      supplierProps?.contacts?.length === 1
                        ? 'flex'
                        : 'none'
                    }>
                    <IconButton
                      borderRadius="10px"
                      icon={
                        <Icon as={FiPlus} color="white" w="30px" h="30px" />
                      }
                      onClick={onAddContact}
                      aria-label="add"
                      w="33px"
                      h="33px"
                      minW="33px"
                      bg="#93C3B3"
                    />
                    <Text variant="addRowText">
                      {t('suppliers.modals.contact_person.add')}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
            </Flex>
            <Flex></Flex>
          </Flex>
        </Flex>
        <Button
          variant="h1cta"
          w="320px"
          ms="auto"
          px={{ base: 4, md: 4 }}
          onClick={handleSave}
          disabled={loading}
          isLoading={loading}>
          {t('suppliers.save')}
        </Button>
      </ModalContent>
    </Modal>
  );
};
