import {
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import Card from 'components/card/Card';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useGetSuppliers } from 'queries/supplier';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdOutlineMoreHoriz } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Supplier } from 'services/@types';

export default function Suppliers() {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('event_suppliers');
  const params = useParams();

  const eventId = useMemo(() => params.id, [params.id]);

  const result = useGetSuppliers({ eventId });

  const suppliers = useMemo(
    () => result?.data as unknown as Supplier[],
    [result.data],
  );

  const handleRemoveSupplierFromEvent = useCallback((supplierId: string) => {
    console.log(supplierId);
  }, []);

  return (
    <Card
      flex="1"
      borderRadius={{ base: 0, md: '30px' }}
      style={{ gridColumn: 'span 2' }}
      maxHeight="535px"
      h="100%"
      gap="20px"
      minH="535px"
      overflowX="hidden"
      overflowY="auto"
      minW={{ base: '100%', lg: 'auto' }}
      {...style}
      ref={ref}>
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">{t('event.event_suppliers')}</Text>
        <IconButton
          margin={'0 !important'}
          borderRadius="8px"
          maxWidth="24px"
          height="24px"
          minWidth="24px"
          p="0 !important"
          order={{ base: 1, lg: 0 }}
          aria-label="add-event"
          variant={'actionIconBlue'}
          icon={<Icon as={MdAdd} fontSize="16px" />}
          onClick={() => {}}
        />
      </Flex>
      <Flex flexDirection="column" gap="20px">
        {suppliers?.map((supplier) => (
          <Flex
            key={supplier.id}
            alignItems="center"
            w="100%"
            flexDirection="column">
            <Flex
              alignItems="center"
              gap="10px"
              justifyContent="space-between"
              w="100%">
              <Flex alignItems="center" gap="10px">
                <AuthImage
                  path={supplier.media?.[0]?.url || ''}
                  name={supplier.name}
                  w="34px"
                  h="34px"
                  isAvatar
                />
                <Flex flexDirection="column" maxW="140px">
                  <Text variant="eventSupplierName" noOfLines={1}>
                    {supplier.name}
                  </Text>
                  <Text variant="createQuoteCheckboxText" noOfLines={1}>
                    {supplier.email}
                  </Text>
                </Flex>
              </Flex>
              <Menu>
                <MenuButton aria-label="more">
                  <Icon
                    as={MdOutlineMoreHoriz}
                    color="#93C3B3"
                    w="20px"
                    h="20px"
                  />
                </MenuButton>
                <MenuList p="0" overflow="hidden">
                  <MenuItem
                    gap="13px"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => handleRemoveSupplierFromEvent(supplier.id)}
                    h="100%">
                    <Text variant="eventTabButtonTitle">
                      {t('event.remove_from_event')}
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Divider />
          </Flex>
        ))}
      </Flex>
    </Card>
  );
}
