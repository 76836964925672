import { Flex, Hide, HStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { RootState } from 'contexts/redux/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IEvent, MeetingWithCalendar } from 'services/@types';
import _eventService from 'services/event.api';
import _IMeetingService from 'services/meeting.api';
import Messages from '../../managment/events/eventInfo/components/Messages';
import Tracker from '../../managment/events/eventInfo/components/Tracker';
import AnchorMeetings from './AnchorMeetings';
import { ClientBrief } from './ClientBrief';
import ClientDetails from './ClientDetails';
import ClientDocuments from './ClientDocuments';
import { ClientQuote } from './ClientQuote';
import Contracts from './Contracts';
import FinanceCard from './financeCard/FinanceCard';
import LineUp from './LineUp';
import Meetings from './Meetings';
import SupplierQuotes from './SupplierQuotes';
import Suppliers from './Suppliers';
import TaskList from './Tasks';

export default function EventPageBottomCards() {
  const { t } = useTranslation();
  const currentTab = useSelector(
    (state: RootState) => state.event.currentEventTab,
  );
  const params = useParams();
  const eventId = params?.id;

  const { data: meetingsAndEventDetails, isLoading } = useQuery<{
    meetings: MeetingWithCalendar[];
    eventDetails: IEvent;
  }>({
    queryKey: [`getMeetings-${eventId}`],
    queryFn: async () => {
      const meetingsResult =
        await _IMeetingService.getMeetings<MeetingWithCalendar>({
          eventId,
          populate: 'calendarId',
          limit: 30,
          sortBy: 'startDate:desc',
        });
      const eventDetails = await _eventService.getEvent(eventId);

      return {
        meetings: meetingsResult.results,
        eventDetails,
      };
    },
    retry: false,
    enabled: !!params?.id,
  });

  if (currentTab === 'main') {
    return (
      <Flex flexDir="column" w="100%" pb="40px" gap="24px">
        <HStack
          spacing={0}
          gap={{ base: 2, lg: 5 }}
          wrap={{ base: 'wrap', lg: 'nowrap' }}>
          <TaskList isLoading={isLoading} shouldScroll />
          <Tracker
            componentTitle={t('event.event_tracker')}
            customEventCreation
          />
          <Meetings
            meetingsAndEventDetails={meetingsAndEventDetails}
            isLoading={isLoading}
          />
          <Hide above="lg">
            <Messages minH="235px" />
          </Hide>
        </HStack>
        <Hide below="lg">
          <Messages minH="235px" />
        </Hide>
      </Flex>
    );
  }

  if (currentTab === 'client_file') {
    return (
      <HStack
        spacing={0}
        gap={{ base: 3, lg: 5 }}
        pb="40px"
        wrap="wrap"
        w="100%">
        <HStack
          w="100%"
          gap={{ base: 3, lg: 5 }}
          spacing={0}
          wrap={{ base: 'wrap', lg: 'nowrap' }}
          align="stretch">
          <ClientDetails eventId={eventId} />
          <ClientBrief />
          <ClientQuote eventId={eventId} />
        </HStack>
        <HStack
          w="100%"
          align="start"
          spacing={0}
          flex={1}
          gap={{ base: 3, lg: 5 }}
          wrap={{ base: 'wrap', lg: 'nowrap' }}>
          <LineUp />
          <ClientDocuments />
          <AnchorMeetings />
        </HStack>
      </HStack>
    );
  }

  if (currentTab === 'supplier_assignment') {
    return (
      <HStack
        spacing={0}
        gap={{ base: 3, lg: 5 }}
        pb="40px"
        wrap="wrap"
        w="100%">
        <HStack
          w="100%"
          gap={{ base: 3, lg: 5 }}
          spacing={0}
          wrap={{ base: 'wrap', lg: 'nowrap' }}
          align="start">
          <SupplierQuotes />
          <Suppliers />
          <Contracts />
        </HStack>
      </HStack>
    );
  }
  if (currentTab === 'finance') {
    return <FinanceCard eventId={eventId} />;
  }

  return <></>;
}
