import {
  Button,
  Divider,
  Flex,
  Hide,
  Icon,
  IconButton,
  Image,
  Input,
  Text,
} from '@chakra-ui/react';
import TrashIcon from 'assets/img/quote/QuotePreview.png';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPaperclip, FiX } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import AssignEvent from './components/AssignEvent';
import Clients from './components/Clients';
import DetailsOfReceipts from './components/DetailsOfReceipts';
import FormHeader from './components/FormHeader';
import Products from './components/Products';
import useInvoiceForm from './hooks/useInvoiceForm';

export default function InvoiceForm() {
  const { t, i18n } = useTranslation();
  const params = useParams();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    documentType,
    handleOpenEventDialog,
    invoice,
    event,
    handleRemoveEvent,
    clientsList,
    handleOpenClientDialog,
    setClient,
    removeChosenClient,
    handleChangeInvoice,
    handleChangeFile,
    removeFile,
    handleChangeDetailsOfReceipts,
    handleSelectedProduct,
    currencyIcon,
    handleChangeProductVatIncluded,
    handleUploadCompleted,
    error,
    isAllProductsVatIncluded,
    handleChangeAllProductsVatIncluded,
    handleChangeProductDescription,
    handleChangeProductAmount,
    handleChangeProductPrice,
    handleRemoveProduct,
    handleAddProduct,
    updateProductByCurrencyChange,
    saveAsDraft,
    sendInvoice,
    handleOpenQuoteDialog,
  } = useInvoiceForm();

  return (
    <Flex
      w={'100%'}
      pt={{
        base: '210px',
        md: '80px',
        xl: '80px',
      }}
      flexDirection="column"
      gap="8px">
      <Card
        w="100%"
        gap="36px"
        p="24px"
        borderRadius={{ base: 0, md: '30px' }}
        maxW="1097px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="cardTitle">
            {params.invoiceId
              ? t('update_invoice.update', {
                  documentType: t(
                    `invoicesAndReceipts.document_type.${documentType}`,
                  )?.toLowerCase(),
                })
              : t('create_invoice.create', {
                  documentType: t(
                    `invoicesAndReceipts.document_type.${documentType}`,
                  )?.toLowerCase(),
                })}
          </Text>
          <Flex alignItems="center" gap="12px">
            {documentType !== 'tax_invoice' && (
              <Hide below="md">
                <AssignEvent
                  handleOpenEventDialog={handleOpenEventDialog}
                  event={event}
                  handleRemoveEvent={handleRemoveEvent}
                  w={{ base: '100%', md: '200px' }}
                  maxW={{ base: '100%', md: '200px' }}
                />
              </Hide>
            )}
            <Flex
              cursor="pointer"
              bg="#0000004D"
              borderRadius="10px"
              w="30px"
              h="30px"
              onClick={() => {}}
              alignItems="center"
              justifyContent="center">
              <Image src={TrashIcon} alt="trash" w="20px" h="20px" />
            </Flex>
          </Flex>
        </Flex>
        {documentType !== 'tax_invoice' && (
          <Hide above="md">
            <AssignEvent
              handleOpenEventDialog={handleOpenEventDialog}
              event={event}
              handleRemoveEvent={handleRemoveEvent}
              w={{ base: '100%', md: '200px' }}
              maxW={{ base: '100%', md: '200px' }}
            />
          </Hide>
        )}
        <FormHeader
          handleOpenEventDialog={handleOpenEventDialog}
          event={event}
          documentType={documentType}
          handleRemoveEvent={handleRemoveEvent}
          handleChange={handleChangeInvoice}
          invoice={invoice}
          removeFile={removeFile}
          handleUploadCompleted={handleUploadCompleted}
          isAllProductsVatIncluded={isAllProductsVatIncluded}
          handleChangeAllProductsVatIncluded={
            handleChangeAllProductsVatIncluded
          }
          handleOpenQuoteDialog={handleOpenQuoteDialog}
        />
        <Divider borderColor="#00000033" />
        <Clients
          clientsList={clientsList}
          handleOpenClientDialog={handleOpenClientDialog}
          setClient={setClient}
          removeChosenClient={removeChosenClient}
        />
        <Divider borderColor="#00000033" />
        <Products
          products={invoice?.products}
          handleSelectedProduct={handleSelectedProduct}
          currencyIcon={currencyIcon}
          handleChangeProductVatIncluded={handleChangeProductVatIncluded}
          handleChangeProductDescription={handleChangeProductDescription}
          handleChangeProductAmount={handleChangeProductAmount}
          handleChangeProductPrice={handleChangeProductPrice}
          error={error}
          handleRemoveProduct={handleRemoveProduct}
          handleAddProduct={handleAddProduct}
          updateProductByCurrencyChange={updateProductByCurrencyChange}
        />
        {/* fields */}
        <TextField
          label={t('create_invoice.comment')}
          placeholder={t('create_invoice.comment')}
          w="100%"
          minHeight={{ base: '233px', md: '203px' }}
          value={invoice?.comments}
          onChange={(e: any) => {
            handleChangeInvoice('comments', e.target.value);
          }}
          iconPointerEvents="auto"
          iconLeft={
            <Flex
              marginLeft={i18n.dir() === 'rtl' ? '0px' : '20px'}
              marginRight={i18n.dir() === 'rtl' ? '20px' : '0px'}
              alignItems="center">
              {(!invoice?.media ||
                !invoice?.media?.find(
                  (m) => m.type === 'invoiceCommentAttachment',
                )) && (
                <>
                  <IconButton
                    aria-label=""
                    icon={<Icon as={FiPaperclip} color="white" />}
                    bg="#93C3B3"
                    borderRadius="10px"
                    w="33px"
                    h="33px"
                    minW="33px"
                    onClick={() => {
                      fileInputRef.current?.click();
                    }}
                  />
                  <Input
                    type="file"
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                    display="none"
                    onChange={(e: any) => {
                      handleChangeFile(e, 'invoiceCommentAttachment');
                    }}
                    ref={fileInputRef}
                  />
                </>
              )}
            </Flex>
          }
        />
        {invoice?.media?.find((m) => m.type === 'invoiceCommentAttachment') && (
          <Flex alignItems="center" gap="4px">
            <Text variant="eventGridCardDesc">
              {
                invoice?.media?.find(
                  (m) => m.type === 'invoiceCommentAttachment',
                )?.displayName
              }
            </Text>
            <Icon
              aria-label=""
              as={FiX}
              cursor="pointer"
              onClick={() => removeFile('invoiceCommentAttachment')}
            />
          </Flex>
        )}
        {/* details of receipts */}
        {documentType !== 'tax_invoice' &&
          documentType !== 'tax_invoice_credit' && (
            <DetailsOfReceipts
              receipientDetails={invoice?.receipientDetails}
              handleChangeDetailsOfReceipts={handleChangeDetailsOfReceipts}
            />
          )}
        {/* fields */}
        <InputField
          label={t(`create_invoice.send_to`)}
          placeholder={t(`create_invoice.send_to`)}
          flex="1"
          w="100%"
          value={invoice?.sendTo}
          onChange={(e: any) => handleChangeInvoice('sendTo', e.target.value)}
        />
        <InputField
          label={t(`create_invoice.legal_text`)}
          placeholder={t(`create_invoice.legal_text`)}
          w="100%"
          value={invoice?.legalText}
          onChange={(e: any) =>
            handleChangeInvoice('legalText', e.target.value)
          }
        />
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          w="100%"
          gap="24px"
          flexDirection={{ base: 'column', md: 'row' }}>
          <Button variant="h2outlined" w="240px" onClick={saveAsDraft}>
            {t('create_invoice.save_as_draft')}
          </Button>
          <Button variant="h1cta" w="240px" onClick={sendInvoice}>
            {t('create_invoice.send_to_customer')}
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
}
