import { useQueryClient } from '@tanstack/react-query';
import { formatToLocaleString } from 'helpers/formatNumber';
import { PAYMENT_KEYS, useGetIncomes } from 'hooks/usePayment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDateRange } from 'utils/date.util';
import { getSortBy } from '../../financeOverview/hooks/useFinance';

export default function useIncomes() {
  const queryClient = useQueryClient();
  const [incomesFilter, setIncomesFilter] = useState<{
    fromDate?: Date;
    toDate?: Date;
    range: string;
    search?: string;
    sortBy?: string;
  }>({
    range: '',
    search: '',
    sortBy: '',
  });

  const IncomesQueryParams = useMemo(() => {
    const params: Record<string, any> = {};
    if (incomesFilter.range) {
      params.range = incomesFilter.range;
    }
    if (incomesFilter.fromDate) {
      params.fromDate = incomesFilter.fromDate;
    }
    if (incomesFilter.toDate) {
      params.toDate = incomesFilter.toDate;
    }
    if (incomesFilter.search) {
      params.search = incomesFilter.search;
    }
    if (incomesFilter.sortBy) {
      const sortBy = getSortBy(incomesFilter.sortBy);
      if (sortBy) {
        params.sortBy = sortBy;
      }
    }
    return params;
  }, [incomesFilter]);

  const { data: incomes } = useGetIncomes(IncomesQueryParams);

  const incomeCalcs = useMemo(() => {
    let expected = 0;
    let received = 0;
    let due = 0;
    incomes?.forEach((income) => {
      expected += income.amount;
      received += income.paidAmount;
      due += income.amount - income.paidAmount;
    });
    return {
      expected,
      received,
      due: due < 0 ? 0 : due,
    };
  }, [incomes]);

  const handleSortIncomes = useCallback((header: string) => {
    setIncomesFilter((prev) => ({
      ...prev,
      sortBy: prev.sortBy === header ? '' : header,
    }));
  }, []);

  useEffect(() => {
    if (incomesFilter.range) {
      const { fromDate, toDate } = getDateRange(
        { range: incomesFilter.range },
        new Date(),
      );
      setIncomesFilter((prev) => ({ ...prev, fromDate, toDate }));
    }
  }, [incomesFilter.range]);
  const statsIncome = useMemo(
    () => [
      {
        title: 'finance_overview.expected',
        value: `₪${formatToLocaleString(incomeCalcs.expected)}`,
        bgColor: '#465D55',
      },
      {
        title: 'finance_overview.received',
        value: `₪${formatToLocaleString(incomeCalcs.received)}`,
        bgColor: 'linear-gradient(86.51deg, #93C3B3 40.99%, #86E6C6 94.26%)',
      },
      {
        title: 'finance_overview.due',
        value: `₪${formatToLocaleString(incomeCalcs.due)}`,
        bgColor: 'linear-gradient(86.51deg, #93C3B3 40.99%, #86E6C6 94.26%)',
      },
    ],
    [incomeCalcs],
  );

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: PAYMENT_KEYS.lists() });
  }, [IncomesQueryParams]);

  return {
    incomes,
    statsIncome,
    incomesFilter,
    setIncomesFilter,
    handleSortIncomes,
  };
}
