import { Flex, IconButton, Text } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FiPaperclip } from 'react-icons/fi';
import { UrlItem } from 'services/@types';
import _uploadService from 'services/upload.api';

interface INoteAndTermsProps {
  comment: string;
  paymentTerms: string;
  legalText: string;
  media?: UrlItem;
}

export default function NoteAndTerms(props: INoteAndTermsProps) {
  const { comment, paymentTerms, legalText, media } = props;
  const { t } = useTranslation();
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const handleDownloadFile = useCallback(async () => {
    try {
      let fileData = fileUrl;

      if (!fileData) {
        const response = await _uploadService.getUpload(media?.url);
        fileData = URL.createObjectURL(response);
      }
      const a = document.createElement('a');
      a.href = fileData;
      a.download = media?.displayName;
      a.click();
      if (!fileUrl) {
        setFileUrl(fileData);
      }
    } catch (error) {
      console.error(error);
    }
  }, [media, fileUrl]);

  if (!comment && !paymentTerms && !legalText) {
    return <Flex flex={2} />;
  }

  return (
    <Flex flex={2} flexDir="column" gap="36px">
      <Flex flexDir="column" gap="15px" display={comment ? 'flex' : 'none'}>
        <Text variant="previewQuoteTitle">{t('quote_preview.note')}</Text>
        <Text variant="previewQuoteNote">{comment}</Text>
        {media?.url && (
          <Flex alignItems="center" gap="12px">
            <Text variant="previewQuoteContactNumber">
              {media?.displayName || t('quote_preview.download_attachment')}
            </Text>
            <IconButton
              aria-label="download file"
              bg="#93C3B3"
              borderRadius="10px"
              w="33px"
              h="33px"
              minW="33px"
              icon={<FiPaperclip color="#fff" />}
              onClick={handleDownloadFile}
            />
          </Flex>
        )}
      </Flex>
      <Flex
        flexDir="column"
        gap="8px"
        bg="#93C3B333"
        borderRadius="16px"
        p="8px 12px"
        display={paymentTerms ? 'flex' : 'none'}>
        <Text variant="previewQuotePaymentTermsTitle">
          {t('quote_preview.payment_terms')}:
        </Text>
        <Text variant="previewQuotePaymentTermsNote">{paymentTerms}</Text>
      </Flex>
      <Text
        variant="previewQuoteCopyright"
        display={legalText ? 'flex' : 'none'}>
        {legalText}
      </Text>
    </Flex>
  );
}
