import { Button, Flex, Image, Text, useToast, VStack } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Icon from 'assets/svg/notification/askingForQuote.svg';
import EventDueIcon from 'assets/svg/notification/event-due-icon.svg';
import QuoteDeadlineIcon from 'assets/svg/notification/quote-deadline-icon.svg';
import axios from 'axios';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { updateNotification } from 'contexts/redux/notifications/notificationsSlice';
import { AppDispatch } from 'contexts/redux/store';
import moment from 'moment';
import 'moment/locale/he';
import { createEntityHook } from 'queries/entity';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BusinessEventPlan, INotification } from 'services/@types';
import { AppNotificationEventName } from 'services/@types/notificationEventNames';
import _businessEventPlanService from 'services/businessEventPlan.api';
import { getNotificationMomentFormat } from './NotificationComponent';

export default function ButtonNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const { t, i18n } = useTranslation();
  const myBusiness = useMyBusiness();
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  const toast = useToast();
  const icon = useMemo(() => {
    if (
      notification.notificationEventName ===
      AppNotificationEventName.EventDuePassed
    ) {
      return EventDueIcon;
    }
    if (
      notification.notificationEventName ===
      AppNotificationEventName.QuoteDeadlineReminder
    ) {
      return QuoteDeadlineIcon;
    }
    return Icon;
  }, [notification.notificationEventName]);

  const entity = createEntityHook(notification?.type);

  const { data: businessEventPlan } = useQuery<BusinessEventPlan>({
    queryKey: [
      `getEventParticipants-${notification?.entityId}-${myBusiness?.id}`,
    ],
    queryFn: async () => {
      const result = await _businessEventPlanService.getBusinessEventPlans({
        eventId: notification?.entityId,
      });

      const data = result.results[0];
      return data || null;
    },
    enabled: !!notification?.entityId && !!myBusiness?.id,
  });

  const handleButtonClick = useCallback(async () => {
    if (!notification.buttonLink) return;

    if (
      notification.notificationEventName ===
      AppNotificationEventName.EventDuePassed
    ) {
      if (!myBusiness?.id) return;

      if (!businessEventPlan) return;
      const businessEventPlanQueryKey = `getEventParticipants-${notification?.entityId}-${myBusiness?.id}`;
      const res = await _businessEventPlanService.updateBusinessEventPlan(
        businessEventPlan?.id,
        {
          status: 'post',
        },
      );
      if (res) {
        queryClient.invalidateQueries({
          queryKey: [businessEventPlanQueryKey],
        });
        toast({
          title: t('event.status_change.toast_success'),
          variant: 'main',
          status: 'success',
        });
      }
    } else {
      try {
        const result = await axios.get(notification?.buttonLink);

        if (result.status === 200) {
          entity.invalidate(queryClient, notification?.entityId);
          toast({
            title: t('toast.title.operation_succeeded'),
            variant: 'green',
            status: 'success',
          });
        }
      } catch (e) {
        console.warn(`failed to click button: ${e}`);
      }
    }

    await dispatch(
      updateNotification({
        notificationId: notification?.id,
        updates: { visible: false },
      }),
    );
  }, [
    notification?.buttonLink,
    notification?.notificationEventName,
    notification?.id,
    notification?.entityId,
    businessEventPlan,
    dispatch,
    myBusiness?.id,
    queryClient,
    toast,
    t,
    entity,
  ]);
  const handleButtonUnderlineClick = useCallback(async () => {
    if (!notification.buttonUnderlineLink) return;

    await dispatch(
      updateNotification({
        notificationId: notification?.id,
        updates: { visible: false },
      }),
    );
    axios
      .get(notification.buttonUnderlineLink)
      .then(() => {
        entity.invalidate(queryClient, notification?.entityId);
        toast({
          title: t('toast.title.operation_succeeded'),
          variant: 'green',
          status: 'success',
        });
      })
      .catch((e) => {
        console.warn(`failed to click button underline: ${e}`);
      });
  }, [
    notification?.buttonUnderlineLink,
    notification?.id,
    notification?.entityId,
    dispatch,
    entity,
    queryClient,
    toast,
    t,
  ]);

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  return (
    <Flex
      ref={notificationRef}
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="8px 8px"
      flexDirection="column"
      gap="36px">
      <Flex alignItems="start" gap="12px" p="0 5px">
        <Image src={icon} alt="" w="24px" h="24px" />
        <Text variant="cardSubTitle" color="#1B2037" fontSize="sm">
          {/* <span style={{ fontWeight: 700 }}>{'invite?.fullName' || 'Ana'}</span>{' '}
          is asking for a quote */}
          {t(notification.message, {
            name: notification?.variables?.name,
          })}
        </Text>
      </Flex>
      <VStack gap="12px">
        <VStack spacing={2} w="100%">
          <Button variant="h1cta" w="100%" onClick={handleButtonClick}>
            {/* View request */}
            {t(notification.buttonText)}
          </Button>
          <Button
            variant="h4"
            textDecorationLine={'underline'}
            fontWeight="500"
            display={notification.buttonUnderlineLink ? 'block' : 'none'}
            w="100%"
            onClick={handleButtonUnderlineClick}>
            {t(notification.buttonUnderlineText)}
          </Button>
        </VStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {/* 8 Feb */}
          {moment(notification.createdAt).format(
            getNotificationMomentFormat(i18n.language),
          )}
        </Text>
      </VStack>
    </Flex>
  );
}
