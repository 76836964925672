import {
  Button,
  Flex,
  Icon,
  Spinner,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { AnimatePresence, motion } from 'framer-motion';
import { useInvoices } from 'hooks/useInvoice';
import {
  animatedContainerItemStyles,
  animatedContainerStyles,
} from 'motionVariants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { IInvoice } from 'services/@types';
import Invoices from './components/List';
import NoInvoices from './components/NoInvoices';
import NoSearchedInvoices from './components/NoSearchedInvoices';
import SelectTypeModal from './components/SelectTypeModal';
import useDocumentType from './hooks/useDocumentType';

export default function InvoicesOverview() {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const {
    isOpen: isOpenSelectType,
    onOpen: onOpenSelectType,
    onClose: onCloseSelectType,
  } = useDisclosure();
  const {
    options,
    showedDocumentType,
    selectedDocumentType,
    onSelectedDocumentType,
    documentType: type,
  } = useDocumentType();

  const { data: invoices, isLoading } = useInvoices({
    ...(search ? { search } : {}),
    ...(type ? { type: type as IInvoice['type'] } : {}),
    populate: 'clients.client,clients.contacts', // populate client and contacts
  });

  if (invoices?.results?.length === 0 && search === '' && type === '') {
    return <NoInvoices />;
  }

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}
      flexDirection="column"
      gap="8px">
      <Card
        gap={5}
        h="calc(100vh - 170px)"
        borderRadius={{ base: 0, md: '30px' }}>
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          justify="space-between"
          gap="12px"
          flexDirection={{ base: 'column', md: 'row' }}>
          <Text variant="cardTitle">{t('invoices_overview.documents')}</Text>
          <Flex gap="12px" alignItems="center">
            <InputField
              w={{ base: '100%', md: 'auto' }}
              placeholder={t('invoices_overview.search')}
              iconRight={<Icon as={FiSearch} w="20px" h="20px" />}
              onChange={(e: any) => setSearch(e.target.value)}
              value={search}
            />
            <Button
              variant="h1cta"
              h="50px"
              minH="50px"
              onClick={onOpenSelectType}
              w={{ base: '110px', md: 'auto' }}
              leftIcon={<Icon as={FaPlus} />}>
              {t(
                !isMobile
                  ? 'invoices_overview.new_document'
                  : 'invoices_overview.new',
              )}
            </Button>
          </Flex>
        </Flex>
        <AnimatePresence mode="wait">
          <motion.div
            variants={animatedContainerStyles}
            initial="hidden"
            style={{
              width: 'inherit',
              height: 'inherit',
              overflow: 'auto',
            }}
            animate="visible">
            {invoices?.results?.length === 0 && search !== '' && (
              <NoSearchedInvoices search={search} />
            )}
            {(invoices?.results?.length !== 0 || type !== '') && (
              <motion.div
                variants={animatedContainerItemStyles}
                style={{ width: 'inherit', height: 'inherit' }}>
                <Invoices
                  invoices={invoices?.results || []}
                  options={options}
                  showedDocumentType={showedDocumentType}
                  selectedDocumentType={selectedDocumentType}
                  onSelectedDocumentType={onSelectedDocumentType}
                />
              </motion.div>
            )}
            {isLoading && (
              <Flex
                alignItems="center"
                justifyContent="center"
                h="100%"
                flexDirection="column">
                <Spinner />
              </Flex>
            )}
          </motion.div>
        </AnimatePresence>
      </Card>
      <SelectTypeModal isOpen={isOpenSelectType} onClose={onCloseSelectType} />
    </Flex>
  );
}
